
import './App.css';
import Home from "./Pages/Home";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import 'glightbox/dist/css/glightbox.min.css';

function App() {


  const router = createBrowserRouter([

     {
       path: "/",
       element: <Home />,
     }
   ]);
 

  return (
   <>
    <RouterProvider router={router} />
   </>
  );
}

export default App;
