import "./home.css";
import banner from "../img/hero-bg.jpg";
import profileImg from "../img/profile-img.jpg";
import { useState, useEffect, useRef } from "react";
import portfolio1 from "../img/masonry-portfolio/masonry-portfolio-1.jpg";
import portfolio2 from "../img/masonry-portfolio/masonry-portfolio-2.jpg";
import portfolio3 from "../img/masonry-portfolio/masonry-portfolio-3.jpg";
import portfolio4 from "../img/masonry-portfolio/masonry-portfolio-4.jpg";
import portfolio5 from "../img/masonry-portfolio/masonry-portfolio-5.jpg";
import portfolio6 from "../img/masonry-portfolio/masonry-portfolio-6.jpg";
import portfolio7 from "../img/masonry-portfolio/masonry-portfolio-7.jpg";
import portfolio8 from "../img/masonry-portfolio/masonry-portfolio-8.jpg";
import portfolio9 from "../img/masonry-portfolio/masonry-portfolio-9.jpg";
import Isotope from 'isotope-layout';
import GLightbox from 'glightbox';
import Typed from 'typed.js';

const Home = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const isotopeRef = useRef(null);
  const [activeFilter, setActiveFilter] = useState('*');
  const typedRef = useRef(null);
  const [activeSection, setActiveSection] = useState('hero');

  const StatsItem = ({ icon, end, label }) => {
    const [count, setCount] = useState(0);
    const [hasAnimated, setHasAnimated] = useState(false);
    const countRef = useRef(null);
    
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          const [entry] = entries;
          if (entry.isIntersecting && !hasAnimated) {
            // Start animation when element comes into view
            setHasAnimated(true);
            
            const duration = 1000; // 1 second duration
            const steps = 50; // Number of steps
            const stepValue = end / steps;
            const stepDuration = duration / steps;
            let currentStep = 0;

            const timer = setInterval(() => {
              currentStep++;
              setCount(Math.min(Math.round(stepValue * currentStep), end));
              
              if (currentStep >= steps) {
                clearInterval(timer);
              }
            }, stepDuration);

            return () => clearInterval(timer);
          }
        },
        { threshold: 0.5 } // Trigger when 50% of the element is visible
      );

      if (countRef.current) {
        observer.observe(countRef.current);
      }

      return () => {
        if (countRef.current) {
          observer.unobserve(countRef.current);
        }
      };
    }, [end, hasAnimated]);

    return (
      <div className="col-lg-3 col-md-6 d-flex flex-column align-items-center" ref={countRef}>
        <i className={`bi ${icon}`}></i>
        <div className="stats-item">
          <span className="purecounter">{count}</span>
          <p>{label}</p>
        </div>
      </div>
    );
  };

  const SkillItem = ({ skill, percentage }) => {
    return (
      <div className="progress">
        <span className="skill">
          <span>{skill}</span> <i className="val">{percentage}%</i>
        </span>
        <div className="progress-bar-wrap">
          <div 
            className="progress-bar" 
            role="progressbar" 
            aria-valuenow={percentage} 
            aria-valuemin="0" 
            aria-valuemax="100"
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
      </div>
    );
  };

  const ResumeItem = ({ title, period, organization, location, description, items }) => {
    return (
      <div className="resume-item">
        <h4>{title}</h4>
        {period && <h5>{period}</h5>}
        {organization && <p><em>{organization}</em></p>}
        {location && <p><em>{location}</em></p>}
        {description && <p>{description}</p>}
        {items && (
          <ul>
            {items.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  const PortfolioItem = ({ image, title, category, filter }) => {
    return (
      <div className={`portfolio-item ${filter}`}>
        <img src={image} className="img-fluid" alt={title} />
        <div className="portfolio-info">
          <h4>{title}</h4>
          <p>Lorem ipsum, dolor sit</p>
          <a 
            href={image} 
            title={title} 
            data-gallery={`portfolio-gallery-${category}`} 
            className="glightbox preview-link"
          >
            <i className="bi bi-zoom-in"></i>
          </a>
          <a href="#" title="More Details" className="details-link">
            <i className="bi bi-link-45deg"></i>
          </a>
        </div>
      </div>
    );
  };

  const ServiceItem = ({ color, icon, title, description, delay }) => {
    return (
      <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={delay}>
        <div className={`service-item item-${color} position-relative`}>
          <div className="icon">
            <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
              <path stroke="none" strokeWidth="0" fill="#f5f5f5" d={icon} />
            </svg>
            <i className={`bi ${icon}`}></i>
          </div>
          <a href="#" className="stretched-link">
            <h3>{title}</h3>
          </a>
          <p>{description}</p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    // Initialize GLightbox
    GLightbox({
      selector: '.glightbox'
    });

    // Initialize Isotope with a slight delay to ensure content is loaded
    setTimeout(() => {
      isotopeRef.current = new Isotope('.isotope-container', {
        itemSelector: '.portfolio-item',
        layoutMode: 'masonry',
        masonry: {
          columnWidth: '.portfolio-item'
        }
      });
    }, 200);

    // Cleanup
    return () => {
      if (isotopeRef.current) {
        isotopeRef.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    const typed = new Typed('.typed', {
      strings: ['Designer', 'Developer', 'Freelancer', 'Photographer'],
      typeSpeed: 50,
      backSpeed: 30,
      backDelay: 2000,
      loop: true
    });

    // Cleanup
    return () => {
      typed.destroy();
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const sections = [
        'hero',
        'about',
        'stats',
        'skills',
        'resume',
        'portfolio',
        'services',
        'contact'
      ];

      const sectionElements = sections.map(id => {
        const element = document.getElementById(id);
        if (element) {
          return {
            id,
            offset: element.offsetTop - 100, // Adjust offset as needed
            height: element.offsetHeight
          };
        }
        return null;
      }).filter(Boolean);

      const scrollPosition = window.scrollY + window.innerHeight / 3;

      for (let i = 0; i < sectionElements.length; i++) {
        const { id, offset, height } = sectionElements[i];
        const nextOffset = sectionElements[i + 1]?.offset || Infinity;

        if (scrollPosition >= offset && scrollPosition < nextOffset) {
          setActiveSection(id);
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    // Initial check
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
    if (isotopeRef.current) {
      isotopeRef.current.arrange({
        filter: filter === '*' ? '' : filter
      });
    }
  };

  return (
    <>
      <header className="header d-flex flex-column justify-content-center">
        <i 
          className="header-toggle d-xl-none bi bi-list"
          onClick={() => setIsNavOpen(!isNavOpen)}
        />

        <nav className={`navmenu ${isNavOpen ? 'active' : ''}`}>
          <ul>
            <li>
              <a 
                href="#hero" 
                className={activeSection === 'hero' ? 'active' : ''}
              >
                <i className="bi bi-house navicon"></i>
                <span>Home</span>
              </a>
            </li>
            <li>
              <a 
                href="#about" 
                className={activeSection === 'about' ? 'active' : ''}
              >
                <i className="bi bi-person navicon"></i>
                <span>About</span>
              </a>
            </li>
            <li>
              <a 
                href="#resume" 
                className={activeSection === 'resume' ? 'active' : ''}
              >
                <i className="bi bi-file-earmark-text navicon"></i>
                <span>Resume</span>
              </a>
            </li>
            <li>
              <a 
                href="#portfolio" 
                className={activeSection === 'portfolio' ? 'active' : ''}
              >
                <i className="bi bi-images navicon"></i>
                <span>Portfolio</span>
              </a>
            </li>
            <li>
              <a 
                href="#services" 
                className={activeSection === 'services' ? 'active' : ''}
              >
                <i className="bi bi-hdd-stack navicon"></i>
                <span>Services</span>
              </a>
            </li>
            <li>
              <a 
                href="#contact" 
                className={activeSection === 'contact' ? 'active' : ''}
              >
                <i className="bi bi-envelope navicon"></i>
                <span>Contact</span>
              </a>
            </li>
          </ul>
        </nav>
      </header>

      <main className="main">
        {/* Hero Section */}
        <section id="hero" className="hero section light-background">
          <img src={banner} alt="banner"  />
          <div className="container" data-aos="zoom-out">
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <h2>Brandon Johnson</h2>
                <p>I'm <span className="typed"></span></p>
                <div className="social-links">
                  <a href="#"><i className="bi bi-twitter-x"></i></a>
                  <a href="#"><i className="bi bi-facebook"></i></a>
                  <a href="#"><i className="bi bi-instagram"></i></a>
                  <a href="#"><i className="bi bi-linkedin"></i></a>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Add remaining sections here... */}


        {/* About Section */}
        <section id="about" className="about section">
          {/* Section Title */}
          <div className="container section-title" data-aos="fade-up">
            <h2>About</h2>
            <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
          </div>

          <div className="container" data-aos="fade-up" data-aos-delay="100">
            <div className="row gy-4 justify-content-center">
              <div className="col-lg-4">
                <img src={profileImg} className="img-fluid" alt="Profile" />
              </div>
              <div className="col-lg-8 content">
                <h2>UI/UX Designer &amp; Web Developer.</h2>
                <p className="fst-italic py-3">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua.
                </p>
                <div className="row">
                  <div className="col-lg-6">
                    <ul>
                      <li><i className="bi bi-chevron-right"></i> <strong>Birthday:</strong> <span>1 May 1995</span></li>
                      <li><i className="bi bi-chevron-right"></i> <strong>Website:</strong> <span>www.example.com</span></li>
                      <li><i className="bi bi-chevron-right"></i> <strong>Phone:</strong> <span>+123 456 7890</span></li>
                      <li><i className="bi bi-chevron-right"></i> <strong>City:</strong> <span>New York, USA</span></li>
                    </ul>
                  </div>
                  <div className="col-lg-6">
                    <ul>
                      <li><i className="bi bi-chevron-right"></i> <strong>Age:</strong> <span>30</span></li>
                      <li><i className="bi bi-chevron-right"></i> <strong>Degree:</strong> <span>Master</span></li>
                      <li><i className="bi bi-chevron-right"></i> <strong>Email:</strong> <span>email@example.com</span></li>
                      <li><i className="bi bi-chevron-right"></i> <strong>Freelance:</strong> <span>Available</span></li>
                    </ul>
                  </div>
                </div>
                <p className="py-3">
                  Officiis eligendi itaque labore et dolorum mollitia officiis optio vero. Quisquam sunt adipisci omnis et ut. Nulla accusantium dolor incidunt officia tempore. Et eius omnis.
                  Cupiditate ut dicta maxime officiis quidem quia. Sed et consectetur qui quia repellendus itaque neque.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Stats Section */}
        <section id="stats" className="stats section">
          <div className="container" data-aos="fade-up" data-aos-delay="100">
            <div className="row gy-4">
              <StatsItem 
                icon="bi-emoji-smile" 
                end={232} 
                label="Happy Clients" 
              />
              <StatsItem 
                icon="bi-journal-richtext" 
                end={521} 
                label="Projects" 
              />
              <StatsItem 
                icon="bi-headset" 
                end={1463} 
                label="Hours Of Support" 
              />
              <StatsItem 
                icon="bi-people" 
                end={15} 
                label="Hard Workers" 
              />
            </div>
          </div>
        </section>

        {/* Skills Section */}
        <section id="skills" className="skills section">
          <div className="container section-title" data-aos="fade-up">
            <h2>Skills</h2>
            <p>Necessitatibus eius consequatur ex aliquid fuga eum quidem sint consectetur velit</p>
          </div>

          <div className="container" data-aos="fade-up" data-aos-delay="100">
            <div className="row skills-content skills-animation">
              <div className="col-lg-6">
                <SkillItem skill="HTML" percentage={100} />
                <SkillItem skill="CSS" percentage={90} />
                <SkillItem skill="JavaScript" percentage={75} />
              </div>

              <div className="col-lg-6">
                <SkillItem skill="PHP" percentage={80} />
                <SkillItem skill="WordPress/CMS" percentage={90} />
                <SkillItem skill="Photoshop" percentage={55} />
              </div>
            </div>
          </div>
        </section>

        {/* Resume Section */}
        <section id="resume" className="resume section">
          <div className="container section-title" data-aos="fade-up">
            <h2>Resume</h2>
            <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                <h3 className="resume-title">Summary</h3>
                <ResumeItem 
                  title="Brandon Johnson"
                  description="Innovative and deadline-driven Graphic Designer with 3+ years of experience designing and developing user-centered digital/print marketing material from initial concept to final, polished deliverable."
                  items={[
                    "Portland par 127,Orlando, FL",
                    "(123) 456-7891",
                    "alice.barkley@example.com"
                  ]}
                />

                <h3 className="resume-title">Education</h3>
                <ResumeItem 
                  title="Master of Fine Arts & Graphic Design"
                  period="2015 - 2016"
                  organization="Rochester Institute of Technology, Rochester, NY"
                  description="Qui deserunt veniam. Et sed aliquam labore tempore sed quisquam iusto autem sit. Ea vero voluptatum qui ut dignissimos deleniti nerada porti sand markend"
                />
                <ResumeItem 
                  title="Bachelor of Fine Arts & Graphic Design"
                  period="2010 - 2014"
                  organization="Rochester Institute of Technology, Rochester, NY"
                  description="Quia nobis sequi est occaecati aut. Repudiandae et iusto quae reiciendis et quis Eius vel ratione eius unde vitae rerum voluptates asperiores voluptatem Earum molestiae consequatur neque etlon sader mart dila"
                />
              </div>

              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                <h3 className="resume-title">Professional Experience</h3>
                <ResumeItem 
                  title="Senior graphic design specialist"
                  period="2019 - Present"
                  organization="Experion, New York, NY"
                  items={[
                    "Lead in the design, development, and implementation of the graphic, layout, and production communication materials",
                    "Delegate tasks to the 7 members of the design team and provide counsel on all aspects of the project.",
                    "Supervise the assessment of all graphic materials in order to ensure quality and accuracy of the design",
                    "Oversee the efficient use of production project budgets ranging from $2,000 - $25,000"
                  ]}
                />
                <ResumeItem 
                  title="Graphic design specialist"
                  period="2017 - 2018"
                  organization="Stepping Stone Advertising, New York, NY"
                  items={[
                    "Developed numerous marketing programs (logos, brochures,infographics, presentations, and advertisements).",
                    "Managed up to 5 projects or tasks at a given time while under pressure",
                    "Recommended and consulted with clients on the most appropriate graphic design",
                    "Created 4+ design presentations and proposals a month for clients and account managers"
                  ]}
                />
              </div>
            </div>
          </div>
        </section>

        {/* Portfolio Section */}
        <section id="portfolio" className="portfolio section">
          <div className="container section-title" data-aos="fade-up">
            <h2>Portfolio</h2>
            <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem.</p>
          </div>

          <div className="container">
            <div className="isotope-layout">
              <ul className="portfolio-filters isotope-filters" data-aos="fade-up" data-aos-delay="100">
                <li 
                  onClick={() => handleFilterClick('*')}
                  className={activeFilter === '*' ? 'filter-active' : ''}
                >
                  All
                </li>
                <li 
                  onClick={() => handleFilterClick('.filter-app')}
                  className={activeFilter === '.filter-app' ? 'filter-active' : ''}
                >
                  App
                </li>
                <li 
                  onClick={() => handleFilterClick('.filter-product')}
                  className={activeFilter === '.filter-product' ? 'filter-active' : ''}
                >
                  Card
                </li>
                <li 
                  onClick={() => handleFilterClick('.filter-branding')}
                  className={activeFilter === '.filter-branding' ? 'filter-active' : ''}
                >
                  Web
                </li>
              </ul>

              <div className="row gy-4 isotope-container" data-aos="fade-up" data-aos-delay="200">
                <PortfolioItem image={portfolio1} title="App 1" category="app" filter="filter-app" />
                <PortfolioItem image={portfolio2} title="Product 1" category="product" filter="filter-product" />
                <PortfolioItem image={portfolio3} title="Branding 1" category="branding" filter="filter-branding" />
                <PortfolioItem image={portfolio4} title="App 2" category="app" filter="filter-app" />
                <PortfolioItem image={portfolio5} title="Product 2" category="product" filter="filter-product" />
                <PortfolioItem image={portfolio6} title="Branding 2" category="branding" filter="filter-branding" />
                <PortfolioItem image={portfolio7} title="App 3" category="app" filter="filter-app" />
                <PortfolioItem image={portfolio8} title="Product 3" category="product" filter="filter-product" />
                <PortfolioItem image={portfolio9} title="Branding 3" category="branding" filter="filter-branding" />
              </div>
            </div>
          </div>
        </section>

        {/* Services Section */}
        <section id="services" className="services section">
          <div className="container section-title" data-aos="fade-up">
            <h2>Services</h2>
            <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit.</p>
          </div>

          <div className="container">
            <div className="row gy-4">
              <ServiceItem
                color="cyan"
                icon="bi-activity"
                title="Nesciunt Mete"
                description="Provident nihil minus qui consequatur non omnis maiores. Eos accusantium minus dolores iure perferendis."
                delay="100"
              />
              <ServiceItem
                color="orange"
                icon="bi-broadcast"
                title="Eosle Commodi"
                description="Ut autem aut autem non a. Sint sint sit facilis nam iusto sint. Libero corrupti neque eum hic non ut nesciunt dolorem."
                delay="200"
              />
              <ServiceItem
                color="teal"
                icon="bi-easel"
                title="Ledo Markt"
                description="Ut excepturi voluptatem nisi sed. Quidem fuga consequatur. Minus ea aut. Vel qui id voluptas adipisci eos earum corrupti."
                delay="300"
              />
              <ServiceItem
                color="red"
                icon="bi-bounding-box-circles"
                title="Asperiores Commodit"
                description="Non et temporibus minus omnis sed dolor esse consequatur. Cupiditate sed error ea fuga sit provident adipisci neque."
                delay="400"
              />
              <ServiceItem
                color="indigo"
                icon="bi-calendar4-week"
                title="Velit Doloremque"
                description="Cumque et suscipit saepe. Est maiores autem enim facilis ut aut ipsam corporis aut. Sed animi at autem alias eius labore."
                delay="500"
              />
              <ServiceItem
                color="pink"
                icon="bi-chat-square-text"
                title="Dolori Architecto"
                description="Hic molestias ea quibusdam eos. Fugiat enim doloremque aut neque non et debitis iure. Corrupti recusandae ducimus enim."
                delay="600"
              />
            </div>
          </div>
        </section>

      </main>

      <footer id="footer" className="footer position-relative light-background">
        <div className="container">
          <h3 className="sitename">Brandon Johnson</h3>
          <p>Et aut eum quis fuga eos sunt ipsa nihil. Labore corporis magni eligendi fuga maxime saepe commodi placeat.</p>
          <div className="social-links d-flex justify-content-center">
            <a href=""><i className="bi bi-twitter-x"></i></a>
            <a href=""><i className="bi bi-facebook"></i></a>
            <a href=""><i className="bi bi-instagram"></i></a>
            <a href=""><i className="bi bi-skype"></i></a>
            <a href=""><i className="bi bi-linkedin"></i></a>
          </div>
          <div className="container">
            <div className="copyright">
              <span>Copyright</span> <strong className="px-1 sitename"></strong> <span>All Rights Reserved</span>
            </div>
           
          </div>
        </div>
      </footer>

      <a href="#" id="scroll-top" className="scroll-top d-flex align-items-center justify-content-center">
        <i className="bi bi-arrow-up-short"></i>
      </a>

     
    </>
  );
};

export default Home;
